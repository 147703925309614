<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
   

        <banner title="PROFESSIONAL PROFILE" :breadcrumb="[
           {
              label: 'Dashboard',
            },
            {
              label: 'Professionals',
            },
            { label: 'Profile' },
          ]">
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-5 col-md-5 col-12" style="padding:5px 5px 5px 5px">
            <div
              class="card custom-card"
              style="
             
                height: 100%;
              "
            >
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder"
                        >Professional Profile</b
                      >
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder"
                        >Professional Profile</b
                      >
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div
                    class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                  >
                    <img
                      style="width: 40%; border-radius: 15px"
                      :src="profileImg"
                      alt=""
                    />
                    <br />
                    <span style="color: #00364f">
                      <p
                        v-if="professionalinfo.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          box-shadow: 2px 2px 5px gray;
                          width: 70px;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        Active
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          box-shadow: 2px 2px 5px gray;
                          width: 70px;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        InActive
                      </p>
                    </span>
                  </div>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                  </h5>

                  <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Code : </b>
                      <span v-if="professionalinfo">{{ professionalinfo.code }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Name : </b>
                      <span v-if="professionalinfo">{{ professionalinfo.name }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOB : </b>
                      <span v-if="professionalinfo"
                        >{{ dateTime(professionalinfo.dob) }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Gender : </b>
                      <span v-if="professionalinfo">{{ professionalinfo.gender }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Phone Number : </b>
                      <span v-if="professionalinfo"
                        >+91-{{ professionalinfo.phone }}</span
                      >
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Email Id : </b>
                      <span v-if="professionalinfo">{{ professionalinfo.email }}</span>
                    </div>
                  </div>
                  <!-- img responsive Pc  -->
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                  >
                    <img
                      style="width: 100%; border-radius: 15px; margin-top: -20px"
                      :src="profileImg"
                      alt=""
                    />
                    <span style="color: #00364f; width: 40%">
                      <p
                        v-if="professionalinfo.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          box-shadow: 2px 2px 5px gray;
                          width: 70px;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        Active
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          box-shadow: 2px 2px 5px gray;
                          width: 70px;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        InActive
                      </p>
                    </span>
                  </div>
                </div>
                <div class="col-12" style="margin-left: 7px">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Address : </b>
                    <span v-if="professionalinfo">{{ professionalinfo.address }} </span>
                  </div>
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>State : </b>
                    <span v-if="professionalinfo.state">{{
                      professionalinfo.state.name
                    }}</span>
                  </div>
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Aadhar No. : </b>
                    <span v-if="professionalinfo.professional_kyc">
                      {{ professionalinfo.professional_kyc.aadharNo }}</span
                    >
                  </div>
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Pan No. : </b>
                    <span v-if="professionalinfo.professional_kyc">
                      {{ professionalinfo.professional_kyc.panNo }}</span
                    >
                  </div>
                </div>

                <!-- Qualifications -->
                <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Qualifications </b>
                  </h5>
                  <div class="text" v-for="(qual,index) in professionalinfo.professional_qualification" :key="index">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    
                    <span v-if="qual.qualification">
                      {{ qual.qualification.qualification }} ({{ qual.qualification.shortName }})</span
                    >
                  </div>
                </div>
                <!-- end Qualifications -->

                <!-- Specialisation -->
                <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Specialisation </b>
                  </h5>
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    
                    <span v-if="professionalinfo.specialization">
                      {{ professionalinfo.specialization }}</span
                    >
                  </div>
                </div>

                <!-- end Specialisation -->

                <div class="row">
                  <div class="col-12 text-end">
                    <button
                      type="button"
                      class="btn text-white btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#doccuments"
                      style="background-color: #f21300; margin-right: 5px"
                    >
                      <b
                        >Kyc Doccuments &nbsp;
                        <i class="fa fa-hand-pointer-o"></i>
                      </b>
                    </button>
                  </div>
                </div>
                <div
                  class="row mt-1"
                  style="background-color: #e3e3e3; border-radius: 15px"
                >
                  <div class="col-12 p-1">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>IP Address :</b>
                      <span v-if="professionalinfo">{{
                        professionalinfo.ipAddress
                      }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Last Login :</b>
                      <span v-if="professionalinfo">{{
                        dateTimeWithTime(professionalinfo.last_login) 
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-7 col-lg-7 col-md-7 col-12" style="padding:5px 5px 5px 5px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card "
              style=" height: 100%"
            >
              <div class="card-body">
                <div class="row" v-if="wallet.id">
                  <div class="col-12">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                  </div>

                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Wallet Balance : </b>
                      <span v-if="wallet.id">Rs.{{ wallet.balanceAmount }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Virtual Account : </b>
                      <span v-if="wallet.id">{{ wallet.merchant_vpa }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Wallet Code : </b>
                      <span v-if="wallet.id">{{ wallet.wallet }} </span>
                    </div>

                    <span style="color: #00364f" v-if="wallet.id">
                      <p
                        v-if="wallet.status == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col">
                    <button
                    @click="wallletFormNull()"
                      type="button"
                      class="btn text-white btn-sm"
                      style="
                        background-color: #f21300;
                        font-size: 13px;
                        letter-spacing: 1px;
                      "
                      data-bs-toggle="modal"
                      data-bs-target="#walletModel"
                    >
                      <b> <font-awesome-icon icon="plus" /> Create Wallet </b>
                    </button>
                  </div>
                </div>
                <hr v-if="wallet.id" style="color: #e3e3e3" />
                <div class="row" v-if="wallet.id">
                  <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-start" style="padding:5px 5px 5px 0px">
                    <h4><b style="color: #00364f; font-weight: bolder">Withdrawls</b></h4>
                  </div>
                  <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-end" style="padding:5px 0px 5px 5px">

                    <!-- <b style="color: #00364f; font-weight: bolder"><button @click="withdrawalFormNull()" type="button"
                        class="btn text-white btn-sm" style="
                            background-color: #f21300;
                            font-size: 13px;
                            letter-spacing: 1px;
                          " data-bs-toggle="modal" data-bs-target="#walletWithDrawModel">
                        <b> <font-awesome-icon icon="plus" /> Withdrawls </b>
                      </button></b> -->

                    <!-- &nbsp;
                    <router-link style="
                        color: #ffff;
                            background-color: #00364f;
                            font-size: 13px;
                            letter-spacing: 1px;
                          " to="/master-distributer/wallet-Withdraw-Req" type="button" class="btn btn-sm my-btn">
                      <small> View All </small>

                    </router-link> -->

                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm text" style="font-size: 14px">
                        <tr class="tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th></th> -->
                          <th class="text-truncate">+</th>
                          <th class="text-truncate">Amount</th>
                          <th class="text-truncate">Ac No.</th>
                          <th class="text-truncate">UPI</th>
                          <th class="text-truncate">Date</th>
                          <th class="text-truncate">Payment Mode</th>

                          <th class="text-truncate">Status</th>
                        </tr>

                        <tbody>
                          <tr v-for="(walletWithdrawReq, index) in walletwithdrawalsReq" :key="index">
                            <td>
                              <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                            </td>

                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ walletWithdrawReq.amount }}
                            </td>
                            <td class="text-truncate">
                              <span v-if="walletWithdrawReq.beneficiaryaccount">
                                {{ walletWithdrawReq.beneficiaryaccount.account_number }}
                              </span>

                              <span v-else> NA </span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="walletWithdrawReq.merchant_vpa">
                                {{ walletWithdrawReq.merchant_vpa }}
                              </span>
                              <span v-else> NA </span>
                            </td>
                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ dateTime(walletWithdrawReq.date) }}
                            </td>
                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ walletWithdrawReq.paymentMode }}
                            </td>

                            <td class="text-truncate" v-if="walletWithdrawReq">
                              <p v-if="walletWithdrawReq.status == 'complete'" class="text-white btn btn-sm" style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>
                              <p v-if="walletWithdrawReq.status == 'cancel'" class="text-white btn btn-sm" style="
                                    background-color: #f21000;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>

                              <p v-if="walletWithdrawReq.status == 'pending'" class="text-white btn btn-sm" style="
                                    background-color: #fc7f03;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>
                              <p v-if="walletWithdrawReq.status == 'reject'" class="text-white btn btn-sm" style="
                                    background-color: black;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>
                            </td>
                        
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr v-if="wallet.id" style="color: #e3e3e3" />

                <!-- start list area  -->
                <div v-if="wallet.id" class="row mt-3">
                  <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-start">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        >Beneficiary Account</b
                      >
                    </h4>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table
                        class="table table-hover table-sm text"
                        style="font-size: 14px"
                      >
                        <tr class="tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th></th> -->
                          <th class="text-truncate">+</th>
                          <th class="text-truncate">Ac. Holder</th>
                          <th class="text-truncate">Ac No.</th>
                          <th class="text-truncate">Account Type</th>
                          <th class="text-truncate">Ifsc</th>
                          <th class="text-truncate">Bank</th>
                          <th class="text-truncate">Mobile No</th>
                          <th class="text-truncate">Status</th>
                          <!-- <th class="text-truncate">Action</th> -->
                        </tr>

                        <tbody>
                          <tr
                            v-for="(beneficiaryaccount, index) in beneficiaryaccounts"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 8px; margin-right: 5px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>

                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_holder_name }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_number }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_type }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.ifsc_code }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.bank_name }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.mobile }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              <p
                                v-if="beneficiaryaccount.status == 'pending'"
                                class="text-white btn btn-sm"
                                style="
                                 background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> {{ beneficiaryaccount.status }}</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                 background-color: green;
                                  
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> {{ beneficiaryaccount.status }}</b>
                              </p>
                            </td>
                            <!-- <td class="text-truncate text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  @click="editBeneficiaryAcount(beneficiaryaccount)"
                                  type="button"
                                  class="btn btns btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>
                              </div>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!--start model  -->
  <div
    class="modal fade"
    id="walletModel"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-white" >
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f;"
          >
            <b>Wallet </b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="
              walletEdit == 'false' ? walletCreateDone() : updatebeneficiaryAcount()
            "
          >


            <div class="row">
              <div class="col-12" v-if="walletForm.errors">
                <div v-if="walletForm.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ walletForm.errors.message }}
                  </div>
                </div>
                <div v-if="walletForm.errors.error">
                  <div v-if="walletForm.errors.error.admin_id">
                    <div class="alert alert-danger" role="alert">
                      {{ walletForm.errors.error.admin_id[0] }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-xl-6 col-md-6 col-sm-12 col-12 p-1"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Mobile Number  <small style="color:#f21000">*</small></label>

                <div class="from-group">
                  <input
                  required
                    type="number"
                    v-model="walletForm.mobile"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Mobile Number"
                    @change="lenthheker($event, 'phoneNumberWallet')"
                  />
        
                </div>
                <span style="color: #f21000" id="phoneNumberWallet"></span>
                <small style="color: red" v-if="walletForm.errors"
                  ><span v-if="walletForm.errors.error"
                    ><span v-if="walletForm.errors.error.mobile">{{
                      walletForm.errors.error.mobile[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12 p-1"
                style="padding: 5px 5px 5px 5px"
              >
                <label>UPI ID  <small style="color:#f21000">*</small></label>

                <div class="from-group">
                  <input
                  required

                    type="text"
                    v-model="walletForm.merchant_vpa"
                    class="form-control"
                    style="height: 30px"
                    placeholder="UPI ID"
                    @change="lenthheker($event, 'upiWallet')"
                  />
                </div>
                <span style="color: #f21000" id="upiWallet"></span>
                <small style="color: red" v-if="walletForm.errors"
                  ><span v-if="walletForm.errors.error"
                    ><span v-if="walletForm.errors.error.merchant_vpa">{{
                      walletForm.errors.error.merchant_vpa[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->

  <!--start model  -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style="border-radius: 50px"
  >
    <div class="modal-dialog">
      <div class="modal-content" style="border-radius: 1.4vw">
        <div class="modal-header bg-white" style="border-radius: 1.4vw">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b>Beneficiary Account </b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="
              edit == 'false' ? beneficiaryaccountAdd() : updatebeneficiaryAcount()
            "
          >
            <div class="row">
              <div class="col-12" v-if="form.errors">
                <div v-if="form.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ form.errors.message }}
                  </div>
                </div>
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Account Holder Name</label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.account_holder_name"
                    style="height: 30px"
                    placeholder="Account Holder"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    class="form-control"
                    style="height: 30px"
                    v-model="form.account_holder_name"
                    placeholder="Account Holder"
                  />
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.account_holder_name">{{
                      form.errors.error.account_holder_name[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Account Number</label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="number"
                    v-model="form.account_number"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Account Number"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="number"
                    v-model="form.account_number"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Account Number"
                  />
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.account_number">{{
                      form.errors.error.account_number[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>

            <div class="row mt-1">
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>IFSC</label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    v-model="form.ifsc_code"
                    class="form-control"
                    style="height: 30px"
                    placeholder="IFSC "
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    v-model="form.ifsc_code"
                    class="form-control"
                    style="height: 30px"
                    placeholder="IFSC "
                  />
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.ifsc_code">{{
                      form.errors.error.ifsc_code[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Bank Name</label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    v-model="form.bank_name"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Bank Name"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    v-model="form.bank_name"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Bank Name"
                  />
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.bank_name">{{
                      form.errors.error.bank_name[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>

            <div class="row mt-1">
              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Phone Number</label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="number"
                    v-model="form.mobile"
                    class="form-control"
                    placeholder=" Mobile Number"
                    style="height: 30px"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="number"
                    v-model="form.mobile"
                    class="form-control"
                    style="height: 30px"
                    placeholder=" Mobile Number"
                  />
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.mobile">{{
                      form.errors.error.mobile[0]
                    }}</span></span
                  ></small
                >
              </div>

              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Account type</label>
                <div class="from-group">
                  <select
                    class="form-select"
                    v-model="form.account_type"
                    aria-label="Default select example"
                    placeholder="Status"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                  >
                    <option value="savings" selected>Savings account</option>
                    <option value="current">Current account</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->

  <!-- Modal kyc doccuments show  -->
  <div
    class="modal fade"
    id="doccuments"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Kyc Doccuments</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm text">
              <tr class="table-wrapper-scroll-y tr-head rounded-circle">
                <th>+</th>
                <th>Name</th>
                <th class="text-end">Action</th>
              </tr>

              <tbody>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="adharFrontImg" alt="" />
                  </td>

                  <td><p>Aadhar Front</p></td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharFront.toLowerCase() == 'pdf'
                            ? openWindow(adharFrontImgPdf)
                            : openWindow(adharFrontImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="adharBackImg" alt="" />
                  </td>

                  <td>Aadhar Back</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharBack.toLowerCase() == 'pdf'
                            ? openWindow(adharBackImgPdf)
                            : openWindow(adharBackImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="panImg" alt="" />
                  </td>

                  <td>
                    <p>Pan Card</p>
                  </td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycPan.toLowerCase() == 'pdf'
                            ? openWindow(panImgPdf)
                            : openWindow(panImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            data-bs-dismiss="modal"
            style="background-color: #f21300"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "SuperAdminProfessionalProfile",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      adharFrontImgPdf: "",
      adharBackImgPdf: "",
      panImgPdf: "",

      loading: false,
      profileImg: this.$store.state.placeholderImg,
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      // passBookImg: this.$store.state.placeholderImg,
      walletEdit: "false",
      walletForm: {
        professional_id: null,
        merchant_vpa: "",
        mobile: "",
        errors: {},
      },
      services: [],
      wallet: {},
      beneficiaryaccounts: [],
      professionalinfo: {},
      professionalkyc: {},
      professional_services: [],

      form: {
        account_number: "",
        account_type: "saving",
        account_holder_name: "",
        ifsc_code: "",
        bank_name: "",
        bank_branch: "Agra",
        mobile: "",
        status: "",
        remark: "",
        ipAddress: "",
        errors: {},
      },
      edit: "false",

      phoneNumberWallet:'false',
      upiWallet:'fasle',
    };
  },
  methods: {
    superadminWalletWithdrawalGet() {
      // this.walletwithdrawalForm.wallet_id = this.wallet.id;
      this.loading = true;
      this.$axios
        .get("superadmin/professionalwalletwithdrawal?per_page=5", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("Req =====");
          console.log(res.data.data.data);
          console.log("Req =====");

          this.walletwithdrawalsReq = res.data.data.data;
        })
        .catch((error) => {
          this.walletwithdrawalForm.errors = error.response.data;
        })
        .finally(() => (this.loading = false));
    },
    wallletFormNull(){
this.walletForm = {}
this.walletForm.errors = {}
this.upiWallet = "false";
    $("#upiWallet").html("<small></small>");
    this.phoneNumberWallet = "false";
    $("#phoneNumberWallet").html("<small></small>");
    },

    lenthheker(event, type) {

var data = event.target.value;
if (type == "phoneNumberWallet") {
  if (data.length != 10) {
    this.phoneNumberWallet = "true";
    $("#phoneNumberWallet").html("<small>Phone Required  10 Digits</small>");
  }
  if (data.length == 10) {
    this.phoneNumberWallet = "false";
    $("#phoneNumberWallet").html("<small></small>");
  }
}

if (type == "upiWallet") {
  if (data.length < 3) {
    this.upiWallet = "true";
    $("#upiWallet").html("<small> Required Minimum 3 Metter</small>");
  }else {
    this.upiWallet = "false";
    $("#upiWallet").html("<small></small>");
  }
}
},
    beneficiaryaccountAdd() {
      this.form.professional_id = this.$route.params.id;
      this.loading = true;
      this.$axios
        .post("superadmin/professionalbeneficiaryaccount", this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);

          res.data;

          toast.success("Beneficiary Account Add Successfully", {
            autoClose: 1000,
          });

          $("#exampleModal").modal("hide");
          this.loadBeneficiaryAccount()();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {

          this.form.errors = error.response.data;

        });
    },
    loadWallet() {
      this.$axios
        .get(`superadmin/professionalwallet?professional_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.wallet = res.data.data;
        });
    },
    walletCreateDone() {

if(this.upiWallet == 'false' && this.phoneNumberWallet == 'false'){
  this.walletForm.professional_id = this.$route.params.id;
      this.$axios
        .post("superadmin/professionalwallet", this.walletForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success("Wallet Created Succesfully", {
            autoClose: 1000,
          });
          $("#walletModel").modal("hide");
          this.loadWallet();
        })
        .catch((error) => {
          this.walletForm.errors = error.response.data
// this.$swal.fire({
//       position: "top-center",
//       icon: "error",
//       title: "<small>" + error.response.data.error + "</small>",
//       showConfirmButton: true,
//       confirmButtonColor: "#00364f",
//     });



  });
  //     }

}

    
    },
    editBeneficiaryAcount(acount) {
      this.form_id = acount.id;
      this.beneficiaryId = acount.id;

      this.form = acount;
      this.edit = "true";
    },
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm a");
      }
      
    },
    updatebeneficiaryAcount() {
      this.$axios
        .put(
          `superadmin/masterdistributer/beneficiaryaccount/${this.beneficiaryId}`,
          this.form,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          res;

          toast.success("Beneficiary Account Updated successfully", {
            autoClose: 1000,
          });

          this.form = {};
          this.form_id = null;
          this.beneficiary_id = null;
          this.edit = "false";
          $("#exampleModal").modal("hide");
          this.loadBeneficiaryAccount();
        });
    },
    loadBeneficiaryAccount() {
      this.$axios
        .get(
          `superadmin/professionalbeneficiaryaccount?professional_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res.data.data.data);
          this.beneficiaryaccounts = res.data.data.data;
        });
    },
    loadClients() {
      this.loading = true;
      this.$axios
        .get(
          `superadmin/professional/${this.$route.params.id}`,

          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          console.log(res.data.data);
          this.professionalinfo = res.data.data;

if(this.professionalinfo.photo){

  if (this.$store.state.imgUrl +
          "/professional/" +
          this.professionalinfo.id +
          "/thumbs/" +
          this.professionalinfo.photo) {
        this.profileImg =
          this.$store.state.imgUrl +
          "/professional/" +
          this.professionalinfo.id +
          "/thumbs/" +
          this.professionalinfo.photo;
      }
} else {
        this.profileImg = this.$store.state.placeholderImg;
      }

          if (this.professionalinfo.professional_kyc) {
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              this.professionalinfo.id +
              "/thumbs/" +
              this.professionalinfo.professional_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/professional/" +
                this.professionalinfo.id +
                "/thumbs/" +
                this.professionalinfo.professional_kyc.aadharFrontPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              this.professionalinfo.id +
              "/" +
              this.professionalinfo.professional_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/professional/" +
                this.professionalinfo.id +
                "/" +
                this.professionalinfo.professional_kyc.aadharFrontPhoto;
            }

            this.adharFrontImgPdf = this.adharFrontImg;
            this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

            if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
              this.adharFrontImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }

          if (this.professionalinfo.professional_kyc) {
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              this.professionalinfo.id +
              "/thumbs/" +
              this.professionalinfo.professional_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/professional/" +
                this.professionalinfo.id +
                "/thumbs/" +
                this.professionalinfo.professional_kyc.aadharBackPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              this.professionalinfo.id +
              "/" +
              this.professionalinfo.professional_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/professional/" +
                this.professionalinfo.id +
                "/" +
                this.professionalinfo.professional_kyc.aadharBackPhoto;
            }

            this.adharBackImgPdf = this.adharBackImg;
            this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

            if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
              this.adharBackImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }

          if (this.professionalinfo.professional_kyc) {
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              this.professionalinfo.id +
              "/thumbs/" +
              this.professionalinfo.professional_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/professional/" +
                this.professionalinfo.id +
                "/thumbs/" +
                this.professionalinfo.professional_kyc.panPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              this.professionalinfo.id +
              "/" +
              this.professionalinfo.professional_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/professional/" +
                this.professionalinfo.id +
                "/" +
                this.professionalinfo.professional_kyc.panPhoto;
            }

            this.panImgPdf = this.panImg;
            this.extensionkycPan = this.panImg.split(".").pop();

            if (this.extensionkycPan == "pdf" || this.extensionkycPan == "PDF") {
              this.panImg = "/assets/image/pdf.png";
            }
          } else {
            this.panImg = this.$store.state.placeholderImg;
          }
        })
        .finally(() => (this.loading = false));

   
    },

    openWindow(url) {
      window.open(`${url}`);
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.professionalView
    console.log(this.$store.state.superAdminPageTitles.professionalView)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClients();
      this.loadBeneficiaryAccount();
      this.loadWallet();
      this.superadminWalletWithdrawalGet();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
